import { utils } from '@utocat/catalizr-const-sharing-lib';

import * as dashboardConst from '../const/dashboard.const';
import * as commonConst from '../../../../shared/actions/const/common.const';
import * as investmentConst from '../../../../shared/actions/const/investment.const';
import * as LandingConst from '../../../landing/const/landing.const';
import { dashboardTab } from '../../../../shared/utils/CatalizrConstants';
import { User } from '../../../../shared/utils/user';
import { formatFloatValue } from '../../../../shared/utils/CatalizrFormUtils';
import { isUserBank } from '../../../../shared/utils/JourneyUtils';

/**
 * Returns the object containing the data sent with the redux action
 * @param {object} action redux action object
 */
const getPayloadData = action => {
  const actionPayload = action.payload;
  if (actionPayload && actionPayload.data && actionPayload.data.data) {
    return actionPayload.data.data;
  }
  return actionPayload.data;
};

const dataListDefault = {
  data: [],
  totalItems: 0,
};

const initState = {
  transmit: { ...dataListDefault },
  inProgress: { ...dataListDefault },
  suggestedOperations: { ...dataListDefault },
  selectedSuggestedOperation: 0,
  wallet: { ...dataListDefault },
  cancelled: { ...dataListDefault },
  sharesValuationPositionList: { ...dataListDefault },
  sharesValuationList: { ...dataListDefault, campaignRunning: true },
  shareholdingList: { ...dataListDefault },
  shareholdingWalletDetails: { ...dataListDefault },
  positionsDetails: { ...dataListDefault },
  operationTemplates: { ...dataListDefault },
  fundList: { ...dataListDefault },
  counters: {
    transmit: 0,
    inProgress: 0,
    cancelled: 0,
    closed: 0,
    sharesValuation: 0,
    shareholding: 0,
    operationTemplates: 0,
    suggestedOperations: 0,
    fundList: 0,
  },
  nbDatasFiltered: 0,
  rowSelectedSharesValuationTable: {},
  sharesValuationUpdatedValues: {},
  investorsPositionsUpdatedValues: {},
  search: '',
  furthestStep: {
    step: '',
    user: '',
    createdAt: '',
  },
  latestStep: {
    step: '',
    user: '',
    createdAt: '',
  },
  tabIndex: dashboardTab.IN_PROGRESS, // the selected dashboard tab
  filtersData: {
    isLoading: false,
    filtersApplied: [],
  },
  currentPage: 1,
  perPage: 10,
  currentSort: {
    id: '',
    sort: 'asc',
  },
  currentTimelineEmail: {},
  isLoadingDashboard: false,
  // Errors
  errorLoadingInProgress: '',
  errorLoadingWallet: '',
  errorLoadingTransmit: '',
  errorLoadingCancelled: '',
  errorLoadingSharesValuation: '',
  errorLoadingShareholding: '',
  errorLoadingOperationTemplates: '',
  errorLoadingPositions: '',
  errorLoadingFundList: '',
  manageActionStatus: {
    errorMessage: '',
    informationMessage: '',
    isLoading: false,
  },
  displayDashboardForm: false,
  editDashboardForm: false,
  openedFilters: false,
  editCompanyEmailDashboardForm: false,
  editInvestCategoryDashboardForm: false,
  editInvestOriginDashboardForm: false,
  editCompanyInfo: false,
  // Modals
  cancelledModalIsOpen: false,
  deleteModalIsOpen: false,
  emailModalIsOpen: false,
  closedModalIsOpen: false,
  isinModalIsOpen: false,
  attestationModalIsOpen: false,
  sharesValuationBackofficeModalIsOpen: false,
  rollbackModalIsOpen: false,
  forwardModalIsOpen: false,
  proofPaymentModalIsOpen: false,
};

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case dashboardConst.SET_SELECTED_SUGGESTED_OPERATION: {
      return {
        ...initState,
        selectedSuggestedOperation: action.selectedSuggestedInvestment,
      };
    }
    case dashboardConst.INIT_DASHBOARD_STATE: {
      return {
        ...initState,
        tabIndex: state.tabIndex,
      };
    }
    case commonConst.INIT_STATE:
      return initState;
    case dashboardConst.GET_INVESTMENTS_PENDING: {
      return {
        ...state,
        isLoadingDashboard: true,
      };
    }

    case dashboardConst.INIT_DASHBOARD_COUNTERS_FULFILLED: {
      const payloadData = getPayloadData(action);

      return {
        ...state,
        counters: {
          ...initState.counters,
          ...payloadData,
        },
      };
    }

    case dashboardConst.GET_TRANSMIT_PENDING: {
      return {
        ...state,
        isLoadingDashboard: true,
      };
    }
    case dashboardConst.GET_TRANSMIT_FULFILLED: {
      const payloadData = getPayloadData(action);
      return {
        ...state,
        transmit: {
          ...state.transmit,
          data: payloadData.items,
          totalItems: payloadData.total_items || 0,
        },
        isLoadingDashboard: false,
        errorLoadingTransmit: '',
      };
    }
    case dashboardConst.GET_TRANSMIT_REJECTED: {
      if (action.payload.response.data.message === 'No investments found') {
        return {
          ...state,
          transmit: {
            ...state.transmit,
            data: [],
          },
          isLoadingDashboard: false,
        };
      }
      return {
        ...state,
        isLoadingDashboard: false,
        errorLoadingTransmit: isUserBank()
          ? 'Erreur lors de la récupération des dossiers transmis'
          : 'Erreur lors de la récupération des opérations transmises',
      };
    }

    case dashboardConst.GET_INVESTMENTS_FULFILLED: {
      const payloadData = getPayloadData(action);
      payloadData.items.forEach(investment => {
        // Adding these datas to allow sort by front
        investment.company_name = investment.company.name;
        // for company dashboard
        if (investment.name_surname) {
          investment.investor_name = investment.name_surname;
          if (utils.users.isLegalEntity(investment.investor_title)) {
            // show company_name followed by the siren (use | to separate data because we need
            // to reformat it in the react-table)
            investment.investor_name = `${investment.investor_company_name}|${investment.investor_siren}`;
          }
        }
        // for advisor and backoffice dashboards
        else if (investment.investor.name_surname) {
          const investorData = investment.investor;
          investment.investor_name = investorData.name_surname;
          if (utils.users.isLegalEntity(investorData.title)) {
            // show company_name followed by the siren (use | to separate data because we need
            // to reformat it in the react-table)
            investment.investor_name = `${investorData.company_name}|${investorData.siren}`;
          }
        }
        if (investment.co_investor_name_surname) {
          investment.co_investor_name = investment.co_investor_name_surname;
        } else if (investment.co_investor?.name_surname) {
          const investorData = investment.co_investor;
          investment.co_investor_name = investorData.name_surname;
        }
      });

      return {
        ...state,
        inProgress: {
          ...state.inProgress,
          data: payloadData.items,
          totalItems: payloadData.total_items || 0,
        },
        isLoadingDashboard: false,
        errorLoadingInProgress: '',
      };
    }
    case dashboardConst.GET_SUGGESSTED_INVESTMENTS_FULFILLED: {
      const payloadData = getPayloadData(action);

      payloadData.items.forEach(investment => {
        investment.total_invest = investment.nb_part * investment.part_amount;
        // Adding these datas to allow sort by front
        // investment.company_name = investment.company.name;
        // for company dashboard
        if (investment.name_surname) {
          investment.investor_name = investment.name_surname;
          if (utils.users.isLegalEntity(investment.investor_title)) {
            // show company_name followed by the siren (use | to separate data because we need
            // to reformat it in the react-table)
            investment.investor_name = `${investment.investor_company_name}|${investment.investor_siren}`;
          }
        } else if (investment && investment.investor && investment.investor.name_surname) {
          const investorData = investment.investor;
          investment.investor_name = investorData.name_surname;
          if (utils.users.isLegalEntity(investorData.title)) {
            // show company_name followed by the siren (use | to separate data because we need
            // to reformat it in the react-table)
            investment.investor_name = `${investorData.company_name}|${investorData.siren}`;
          }
        }
        if (investment.co_investor_name_surname) {
          investment.co_investor_name = investment.co_investor_name_surname;
        } else if (investment.co_investor?.name_surname) {
          const investorData = investment.co_investor;
          investment.co_investor_name = investorData.name_surname;
        }
      });

      return {
        ...state,
        suggestedOperations: {
          ...state.suggestedOperations,
          data: payloadData.items,
          totalItems: payloadData.total_items || 0,
        },
        isLoadingDashboard: false,
        errorLoadingInProgress: '',
      };
    }
    case dashboardConst.GET_INVESTMENTS_REJECTED: {
      if (action.payload.response.status >= 400) {
        return {
          ...state,
          inProgress: {
            ...state.inProgress,
            data: [],
          },
          isLoadingDashboard: false,
        };
      }
      return {
        ...state,
        isLoadingDashboard: false,
        errorLoadingInProgress: 'Erreur lors de la récupération des investissements en cours',
      };
    }
    case dashboardConst.GET_WALLET_PENDING: {
      return {
        ...state,
        isLoadingDashboard: true,
      };
    }
    case dashboardConst.GET_WALLET_FULFILLED: {
      const payloadData = getPayloadData(action);

      payloadData.items.forEach(investment => {
        // Adding these datas to allow sort by front
        investment.company_name = investment.company.name;
        // for company dashboard
        if (investment.name_surname) {
          investment.investor_name = investment.name_surname;
          if (utils.users.isLegalEntity(investment.investor_title)) {
            // show company_name followed by the siren (use | to separate data because we need
            // to reformat it in the react-table)
            investment.investor_name = `${investment.investor_company_name}|${investment.investor_siren}`;
          }
        }
        // for advisor and backoffice dashboards
        else if (investment.investor.name_surname) {
          const investorData = investment.investor;
          investment.investor_name = investorData.name_surname;
          if (utils.users.isLegalEntity(investorData.title)) {
            // show company_name followed by the siren (use | to separate data because we need
            // to reformat it in the react-table)
            investment.investor_name = `${investorData.company_name}|${investorData.siren}`;
          }
        }
        if (investment.co_investor_name_surname) {
          investment.co_investor_name = investment.co_investor_name_surname;
        } else if (investment.co_investor?.name_surname) {
          const investorData = investment.co_investor;
          investment.co_investor_name = investorData.name_surname;
        }
      });
      return {
        ...state,
        wallet: {
          ...state.wallet,
          data: payloadData.items,
          totalItems: payloadData.total_items || 0,
        },
        isLoadingDashboard: false,
        errorLoadingWallet: '',
      };
    }

    case dashboardConst.GET_WALLET_REJECTED: {
      if (action.payload.response.status >= 400) {
        return {
          ...state,
          wallet: {
            ...state.wallet,
            data: [],
          },
          isLoadingDashboard: false,
        };
      }
      return {
        ...state,
        isLoadingDashboard: false,
        errorLoadingWallet: 'Erreur lors de la récupération du portefeuille',
      };
    }

    case dashboardConst.GET_CANCELLED_FULFILLED: {
      const payloadData = getPayloadData(action);

      payloadData.items.forEach(investment => {
        // Adding these datas to allow sort by front
        investment.company_name = investment.company.name;
        // for company dashboard
        if (investment.name_surname) {
          investment.investor_name = investment.name_surname;
          if (utils.users.isLegalEntity(investment.investor_title)) {
            // show company_name followed by the siren (use | to separate data because we need
            // to reformat it in the react-table)
            investment.investor_name = `${investment.investor_company_name}|${investment.investor_siren}`;
          }
        }
        // for advisor and backoffice dashboards
        else if (investment.investor.name_surname) {
          const investorData = investment.investor;
          investment.investor_name = investorData.name_surname;
          if (utils.users.isLegalEntity(investorData.title)) {
            // show company_name followed by the siren (use | to separate data because we need
            // to reformat it in the react-table)
            investment.investor_name = `${investorData.company_name}|${investorData.siren}`;
          }
        }
        if (investment.co_investor_name_surname) {
          investment.co_investor_name = investment.co_investor_name_surname;
        } else if (investment.co_investor?.name_surname) {
          const investorData = investment.co_investor;
          investment.co_investor_name = investorData.name_surname;
        }
      });
      return {
        ...state,
        cancelled: {
          ...state.cancelled,
          data: payloadData.items,
          totalItems: payloadData.total_items || 0,
        },
        isLoadingDashboard: false,
        errorLoadingCancelled: '',
      };
    }

    case dashboardConst.GET_CANCELLED_PENDING: {
      return {
        ...state,
        isLoadingDashboard: true,
      };
    }

    case dashboardConst.GET_CANCELLED_REJECTED: {
      if (action.payload.response.status >= 400) {
        return {
          ...state,
          cancelled: {
            ...state.cancelled,
            data: [],
          },
          isLoadingDashboard: false,
        };
      }

      return {
        ...state,
        isLoadingDashboard: false,
        errorLoadingCancelled: 'Erreur lors de la récupération des investissements annulés',
      };
    }

    case dashboardConst.DISPLAY_INVESTMENT_DETAILS_DASHBOARD:
      return {
        ...state,
        displayDashboardForm: action.value,
      };
    case dashboardConst.EDIT_INVESTMENT_DETAILS_DASHBOARD:
      return {
        ...state,
        editDashboardForm: action.value,
      };
    case dashboardConst.EDIT_COMPANY_EMAIL_DASHBOARD:
      return {
        ...state,
        editCompanyEmailDashboardForm: action.value,
      };
    case dashboardConst.EDIT_INVEST_CATEGORY_DASHBOARD:
      return {
        ...state,
        editInvestCategoryDashboardForm: action.value,
      };
    case dashboardConst.EDIT_COMPANY_INFO:
      return {
        ...state,
        editCompanyInfo: action.value,
      };
    case dashboardConst.EDIT_INVEST_ORIGIN_DASHBOARD:
      return {
        ...state,
        editInvestOriginDashboardForm: action.value,
      };
    case dashboardConst.SET_FILTER: {
      return {
        ...state,
        filter: { name: action.filter.name, value: action.filter.value },
      };
    }
    case dashboardConst.GET_FURTHEST_STEP_FULFILLED: {
      return {
        ...state,
        furthestStep: {
          step: action.payload.step,
          stage: action.payload.stage,
          user: action.payload.entity_type,
          createdAt: action.payload.createdAt,
        },
      };
    }
    case dashboardConst.GET_LATEST_STEP_FULFILLED: {
      return {
        ...state,
        latestStep: {
          step: action.payload.step,
          user: action.payload.entity_type,
          createdAt: action.payload.createdAt,
        },
      };
    }
    case investmentConst.POST_CANCELLED_FULFILLED: {
      return {
        ...state,
        cancelledModalIsOpen: false,
      };
    }

    case dashboardConst.SET_SEARCH: {
      return {
        ...state,
        search: action.search,
        currentPage: initState.currentPage,
      };
    }

    case dashboardConst.SET_CANCELLED_MODAL_IS_OPEN: {
      return {
        ...state,
        cancelledModalIsOpen: action.open,
      };
    }

    case dashboardConst.SET_DELETE_MODAL_IS_OPEN: {
      return {
        ...state,
        deleteModalIsOpen: action.open,
      };
    }

    case dashboardConst.SET_ROLLBACK_MODAL_IS_OPEN: {
      return {
        ...state,
        rollbackModalIsOpen: action.open,
      };
    }
    case dashboardConst.SET_FORWARD_MODAL_IS_OPEN: {
      return {
        ...state,
        forwardModalIsOpen: action.open,
      };
    }
    case dashboardConst.SET_PROOF_PAYMENT_MODAL_IS_OPEN: {
      return {
        ...state,
        proofPaymentModalIsOpen: action.open,
      };
    }

    case dashboardConst.SET_CLOSED_MODAL_IS_OPEN: {
      return {
        ...state,
        closedModalIsOpen: action.open,
      };
    }
    case dashboardConst.SET_ISIN_CLOSED_MODAL_IS_OPEN: {
      return {
        ...state,
        isinModalIsOpen: action.open,
      };
    }
    case dashboardConst.SET_ATTESTATION_MODAL_IS_OPEN: {
      return {
        ...state,
        attestationModalIsOpen: action.open,
      };
    }
    case dashboardConst.GET_LATEST_STEP_PENDING:
    case dashboardConst.GET_LATEST_STEP_REJECTED:
    case dashboardConst.GET_FURTHEST_STEP_PENDING:
    case dashboardConst.GET_FURTHEST_STEP_REJECTED: {
      return state;
    }
    case dashboardConst.DASHBOARD_SET_CURRENT_TAB: {
      return {
        ...state,
        tabIndex: action.currentTab,
        currentPage: initState.currentPage,
        currentSort: initState.currentSort,
      };
    }

    case dashboardConst.SET_OPERATION_FLAG_STATUS_FULFILLED: {
      return {
        ...state,
        inProgress: {
          ...state.inProgress,
          data: state.inProgress.data.map(el => {
            return el.id !== action.payload.operationId
              ? el
              : {
                  ...el,
                  is_flagged: action.payload.flagStatus,
                };
          }),
        },
      };
    }

    case investmentConst.GET_OPERATION_FILTERS_PENDING: {
      return {
        ...state,
        filtersData: {
          ...state.filtersData,
          isLoading: true,
        },
      };
    }

    case investmentConst.GET_OPERATION_FILTERS_FULLFILLED:
    case investmentConst.GET_OPERATION_FILTERS_REJECTED: {
      return {
        ...state,
        filtersData: {
          ...state.filtersData,
          isLoading: false,
        },
      };
    }

    case dashboardConst.DASHBOARD_SET_OPENED_FILTERS: {
      return {
        ...state,
        openedFilters: action.payload,
      };
    }
    case dashboardConst.DASHBOARD_FILTERS_DATA: {
      return {
        ...state,
        filtersData: {
          ...state.filtersData,
          ...action.filters,
        },
      };
    }
    case dashboardConst.DASHBOARD_FILTERS_TO_APPLY: {
      return {
        ...state,
        filtersData: { ...state.filtersData, filtersApplied: action.filters },
      };
    }
    case dashboardConst.DASHBOARD_RESET_FILTERS: {
      if (action.user.type === User.COMPANY) {
        return {
          ...state,
          inProgress: { ...state.inProgress, data: state.inProgress.data },
          suggestedOperations: {
            ...state.suggestedOperations,
            data: state.suggestedOperations.data,
          },
          wallet: { ...state.wallet, data: state.wallet.data },
          cancelled: { ...state.cancelled, data: state.cancelled.data },
          sharesValuationList: {
            ...state.sharesValuationList,
            data: state.sharesValuationList.data,
          },
          shareholdingList: {
            ...state.shareholdingList,
            data: state.shareholdingList.data,
          },
          positionsDetails: {
            ...state.positionsDetails,
            data: state.positionsDetails.data,
          },
          fundList: {
            ...state.fundList,
            data: state.fundList.data,
          },
          filtersData: { ...state.filtersData, filtersApplied: [] },
          currentPage: initState.currentPage,
          perPage: initState.perPage,
          currentSort: initState.currentSort,
          search: '',
        };
      } else {
        return {
          ...state,
          transmit: { ...state.transmit, data: state.transmit.data },
          inProgress: { ...state.inProgress, data: state.inProgress.data },
          suggestedOperations: {
            ...state.suggestedOperations,
            data: state.suggestedOperations.data,
          },
          wallet: { ...state.wallet, data: state.wallet.data },
          cancelled: { ...state.cancelled, data: state.cancelled.data },
          filtersData: { ...state.filtersData, filtersApplied: [] },
          currentPage: initState.currentPage,
          perPage: initState.perPage,
          currentSort: initState.currentSort,
          search: '',
        };
      }
    }
    case dashboardConst.DASHBOARD_SET_CURRENT_PAGE: {
      return {
        ...state,
        isLoadingDashboard: true,
        currentPage: action.currentPage,
      };
    }
    case dashboardConst.DASHBOARD_SET_PER_PAGE: {
      return {
        ...state,
        perPage: action.perPage,
        currentPage: action.currentPage,
      };
    }
    case dashboardConst.DASHBOARD_SET_CURRENT_SORT: {
      return {
        ...state,
        currentSort: action.currentSort,
      };
    }
    case LandingConst.START_NEW_OPERATION_FULFILLED:
    case LandingConst.START_NEW_OPERATION_TRANSMIT_FULFILLED:
      return {
        ...state,
        filtersData: { ...state.filtersData, filtersApplied: [] },
        currentPage: initState.currentPage,
        search: '',
      };
    case dashboardConst.REFRESH_REDUX_DATA:
      return {
        ...initState,
        tabIndex: state.tabIndex,
        search: state.search,
        currentPage: state.currentPage,
        filtersData: { ...state.filtersData },
        currentSort: { ...state.currentSort },
      };
    case dashboardConst.EMAIL_MODAL_IS_OPEN:
      return {
        ...state,
        emailModalIsOpen: action.open,
        currentTimelineEmail: action.currentEmail,
        manageActionStatus: {
          errorMessage: '',
          informationMessage: '',
          isLoading: false,
        },
      };
    case dashboardConst.RESEND_EMAIL_PENDING:
      return {
        ...state,
        manageActionStatus: {
          errorMessage: '',
          informationMessage: '',
          isLoading: true,
        },
      };
    case dashboardConst.RESEND_EMAIL_FULFILLED:
      return {
        ...state,
        manageActionStatus: {
          errorMessage: '',
          informationMessage: "L'email a bien été renvoyé",
          isLoading: false,
        },
      };
    case dashboardConst.RESEND_EMAIL_REJECTED:
      return {
        ...state,
        manageActionStatus: {
          errorMessage: 'Une erreur est survenue, veuillez réessayer plus tard',
          informationMessage: '',
          isLoading: false,
        },
      };

    case dashboardConst.GET_SHARES_VALUATION_POSITIONS_LIST_FULFILLED:
      return {
        ...state,
        sharesValuationPositionList: {
          data: action.payload?.data,
          totalItems: action.payload?.data?.length,
        },
        isLoadingDashboard: true,
        errorLoadingSharesValuation: '',
      };

    case dashboardConst.GET_SHARES_VALUATION_POSITIONS_LIST_PENDING:
      return {
        ...state,
        isLoadingDashboard: true,
        errorLoadingSharesValuation: '',
      };

    case dashboardConst.GET_SHARES_VALUATION_POSITIONS_LIST_REJECTED:
      return {
        ...state,
        isLoadingDashboard: true,
        errorLoadingSharesValuation: 'Erreur lors de la récupération des valorisations annuelles',
      };

    case dashboardConst.GET_SHARES_VALUATION_LIST_PENDING:
      return {
        ...state,
        isLoadingDashboard: true,
        errorLoadingSharesValuation: '',
      };

    case dashboardConst.GET_SHARES_VALUATION_LIST_REJECTED:
      return {
        ...state,
        isLoadingDashboard: false,
        errorLoadingSharesValuation: 'Erreur lors de la récupération des valorisations annuelles',
        investorsPositionsUpdatedValues: {},
      };

    case dashboardConst.GET_SHARES_VALUATION_LIST_FULFILLED: {
      let sharesValuationList = action.payload.data.data;
      return {
        ...state,
        isLoadingDashboard: false,
        counters: {
          ...state.counters,
          sharesValuation: sharesValuationList.total_items,
        },
        sharesValuationList: {
          ...state.sharesValuationList,
          data: sharesValuationList.items,
          campaignRunning: sharesValuationList.campaignRunning,
          totalItems: sharesValuationList.total_items,
        },
        errorLoadingSharesValuation: '',
        investorsPositionsUpdatedValues: {},
      };
    }
    case dashboardConst.SET_SHARES_VALUATION_BACKOFFICE_CAMPAIN_RUNNING: {
      const compainRunning = action.companies && action.companies.length !== 0;
      // Setting email_sent property to true after submit
      state.sharesValuationList.data.map(shareValuation => {
        if (action.companies.includes(shareValuation.company_mongo_id)) {
          shareValuation.email_sent = true;
        }
        return shareValuation;
      });
      return {
        ...state,
        sharesValuationBackofficeModalIsOpen: action.open,
        sharesValuationList: {
          ...state.sharesValuationList,
          campaignRunning: compainRunning ? true : state.sharesValuationList.campaignRunning,
        },
      };
    }

    case dashboardConst.SET_SHARES_VALUATION_BACKOFFICE_MODAL_IS_OPEN:
      return {
        ...state,
        sharesValuationBackofficeModalIsOpen: action.open,
      };
    case dashboardConst.SET_SHARES_VALUATION_DETAILS_INIT_VIEW_PENDING:
      return {
        ...state,
        isLoadingDashboard: true,
        errorLoadingSharesValuation: '',
        rowSelectedSharesValuationTable: action.row,
      };
    case dashboardConst.SET_SHARES_VALUATION_DETAILS_INIT_VIEW_REJECTED:
      return {
        ...state,
        isLoadingDashboard: false,
        errorLoadingSharesValuation:
          'Erreur lors de la récupération des valeurs, veuillez réessayer',
        rowSelectedSharesValuationTable: action.row,
      };
    case dashboardConst.SET_SHARES_VALUATION_DETAILS_INIT_VIEW_FULFILLED: {
      let selectedRow = action.res;
      let updatedValuesByCompany = selectedRow.values.reduce((accu, data, index) => {
        accu[index] = {
          value: data.current_price ? formatFloatValue(data.current_price) : '',
          text: data.current_price,
          date: data.current_effective_date ? new Date(data.current_effective_date) : null,
        };
        return accu;
      }, {});
      return {
        ...state,
        rowSelectedSharesValuationTable: action.res,
        sharesValuationUpdatedValues: updatedValuesByCompany,
        isLoadingDashboard: false,
        errorLoadingSharesValuation: '',
      };
    }

    case dashboardConst.GET_UPDATED_SHARES_VALUATION_BY_COMPANY:
      return {
        ...state,
        sharesValuationUpdatedValues: action.updatedValues,
      };

    case dashboardConst.GET_SHAREHOLDING_LIST_PENDING: {
      return {
        ...state,
        isLoadingDashboard: true,
        errorLoadingShareholding: '',
      };
    }
    case dashboardConst.GET_SHAREHOLDING_LIST_FULFILLED: {
      const payloadData = getPayloadData(action);
      return {
        ...state,
        counters: {
          ...state.counters,
          shareholding: payloadData.total_items,
        },
        shareholdingList: {
          ...state.shareholding,
          data: payloadData.items,
          totalItems: payloadData.total_items || 0,
        },
        isLoadingDashboard: false,
        errorLoadingShareholding: '',
      };
    }
    case dashboardConst.GET_SHAREHOLDING_LIST_REJECTED: {
      if (action.payload.response.data.message === 'No investments found') {
        return {
          ...state,
          shareholdingList: {
            ...state.shareholdingList,
            data: [],
          },
          isLoadingDashboard: false,
        };
      }
      return {
        ...state,
        isLoadingDashboard: false,
        errorLoadingShareholding: 'Erreur lors de la récupération du registre',
      };
    }
    case dashboardConst.SET_SHAREHOLDING_DETAILS_INIT_VIEW_PENDING:
      return {
        ...state,
        isLoadingDashboard: true,
        errorLoadingShareholding: '',
      };
    case dashboardConst.SET_SHAREHOLDING_DETAILS_INIT_VIEW_REJECTED:
      return {
        ...state,
        isLoadingDashboard: false,
        errorLoadingShareholding:
          "Erreur lors de la récupération du portefeuille de l'investisseur, veuillez réessayer",
      };
    case dashboardConst.SET_SHAREHOLDING_DETAILS_INIT_VIEW_FULFILLED:
      return {
        ...state,
        shareholdingWalletDetails: {
          ...action.res,
          data: action.res.data,
          detailled: action.res.details,
        },
        isLoadingDashboard: false,
        errorLoadingShareholding: '',
      };

    case dashboardConst.SET_POSITIONS_DETAILS_INIT_VIEW_PENDING:
      return {
        ...state,
        isLoadingDashboard: true,
        errorLoadingPositions: '',
        rowSelectedSharesValuationTable: action.row,
      };
    case dashboardConst.SET_POSITIONS_DETAILS_INIT_VIEW_REJECTED:
      return {
        ...state,
        isLoadingDashboard: false,
        errorLoadingPositions: 'Erreur lors de la récupération des positions, veuillez réessayer',
        rowSelectedSharesValuationTable: action.row,
      };
    case dashboardConst.SET_POSITIONS_DETAILS_INIT_VIEW_FULFILLED: {
      let selectedData = action.res.rows;
      return {
        ...state,
        investorsPositionsUpdatedValues: {},
        positionsDetails: {
          data: selectedData,
          totalItems: action.res.total_items,
        },
        isLoadingDashboard: false,
        errorLoadingPositions: '',
      };
    }

    case dashboardConst.GET_INVESTOR_UPDATED_POSITIONS_BY_COMPANY: {
      return {
        ...state,
        investorsPositionsUpdatedValues: action.updatedQuantities,
      };
    }
    case dashboardConst.SEND_POSITIONS_UPDATED_FULFILLED:
      return {
        ...state,
        investorsPositionsUpdatedValues: action.updatedQuantities,
      };
    case dashboardConst.ADD_NEW_INVESTOR_POSITION_PENDING:
      return {
        ...state,
        manageActionStatus: {
          errorMessage: '',
          informationMessage: '',
          isLoading: true,
        },
      };
    case dashboardConst.ADD_NEW_INVESTOR_POSITION_FULFILLED:
      return {
        ...state,
        manageActionStatus: {
          errorMessage: '',
          informationMessage: 'Votre ajout a bien été pris en compte',
          isLoading: false,
        },
      };
    case dashboardConst.ADD_NEW_INVESTOR_POSITION_REJECTED:
      return {
        ...state,
        manageActionStatus: {
          errorMessage: "Erreur lors de l'insertion, veuillez réessayer plus tard",
          informationMessage: '',
          isLoading: false,
        },
      };
    case commonConst.CLEAR_MESSAGES:
      return {
        ...state,
        manageActionStatus: {
          errorMessage: '',
          informationMessage: '',
          isLoading: false,
        },
      };
    // OPERATION TEMPLATES
    case dashboardConst.GET_OPERATION_TEMPLATES_PENDING: {
      return {
        ...state,
        isLoadingDashboard: true,
      };
    }
    case dashboardConst.GET_OPERATION_TEMPLATES_FULFILLED: {
      const payloadData = getPayloadData(action);
      // payload is an empty object if no templates in db
      let templatesList = [];
      let templatesCount = 0;
      if (payloadData.templates) {
        templatesList = payloadData.templates;
        templatesCount = payloadData.templates.length;
      }
      return {
        ...state,
        operationTemplates: {
          ...state.operationTemplates,
          data: templatesList,
          totalItems: templatesCount,
        },
        isLoadingDashboard: false,
        errorLoadingOperationTemplates: '',
      };
    }
    case dashboardConst.GET_OPERATION_TEMPLATES_REJECTED: {
      return {
        ...state,
        isLoadingDashboard: false,
        errorLoadingOperationTemplates: "Erreur lors de la récupération des modèles d'opérations",
      };
    }
    case dashboardConst.GET_FUND_LIST_PENDING: {
      return {
        ...state,
        isLoadingDashboard: true,
        errorLoadingFundList: '',
      };
    }
    case dashboardConst.GET_FUND_LIST_FULFILLED: {
      const payloadData = getPayloadData(action);
      return {
        ...state,
        counters: {
          ...state.counters,
          fundList: payloadData.total,
        },
        fundList: {
          ...state.fundList,
          data: payloadData.collection,
          totalItems: payloadData.total || 0,
        },
        isLoadingDashboard: false,
        errorLoadingFundList: '',
      };
    }
    case dashboardConst.GET_FUND_LIST_REJECTED: {
      return {
        ...state,
        counters: {
          ...state.counters,
          fundList: 0,
        },
        fundList: {
          ...state.fundList,
          data: [],
          totalItems: 0,
        },
        isLoadingDashboard: false,
        errorLoadingFundList: 'Erreur lors de la récupération des fonds',
      };
    }

    case commonConst.CLEAR_LOGOUT_PENDING:
      return {
        ...initState,
      };
    default:
      return state;
  }
};

export default reducer;
